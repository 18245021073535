import drone3DMarker from "../../gcs_image/3DModel/CesiumDrone.glb";

let { ws3d } = window;
let { Cesium } = window;
let init3DMatrix = {};
/**
 * 죄표를 통한 해당 지면의 높이를 반환
 * 지도상에서 해당 좌표가 랜더링 되어있지 않을 경우 부정확한 값이 들어올 수 있음
 * @param {*} lat
 * @param {*} lng
 * @returns {number}
 */
export const getHeight = (lat, lng) => {
    let height = ws3d.viewer.scene.globe.getHeight(Cesium.Cartographic.fromDegrees(lng, lat));
    return Number(height);
};

/**
 * 미션 비행중 경로를 그리는 함수
 * positions는 {latitude: ,longitude:,altitude :} 의 객체
 * alt 는 드론의 홈포지션의 고도
 * alt 와 position의 고도를 더한값을 포인트의 고도로 주어야 정확한 위치에 표시됨
 * @param {*} positions
 * @param {*} alt
 * @returns {object}
 */
export const createMissionLine = (positions, alt) => {
    /* 
  alt 와 position의 고도를 더한값을 포인트의 고도로 주어야 정확한 위치에 표시됨
  객체 생성시 cesium 객체들은 절대고도를 넣어주기 때문에 상대고도인 positions에 드론의 홈포지션 고도인 alt를 더해줘야 원하는 고도가 나옴
  */
    let linePosition = [];
    for (let i = 0; i < positions.length; i++) {
        linePosition.push(positions[i].longitude);
        linePosition.push(positions[i].latitude);
        linePosition.push(Number(positions[i].altitude) + Number(alt));
    }
    const missionLine = ws3d.viewer.entities.add({
        polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(linePosition),
            width: 5,
        },
    });
    return missionLine;
};

/**
 *
 * @param {*} lat
 * @param {*} lng
 * @param {*} alt
 * @param {*} entitie
 * @returns
 */
export const createLineToGround = (lat, lng, alt, entitie, color) => {
    const Line = ws3d.viewer.entities.add({
        polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights([lng, lat, getHeight(lat, lng) + Number(alt) - 1, lng, lat, 0]),
            width: 4,
            material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(color),
                // color: Cesium.Color.fromCssColorString('#B5FF15'),
                dashLength: 15.0,
            })
        },
    });
    deleteEntitie(entitie);
    return Line;
};

export const updateLineToGround = (lat, lng, alt, entitie, color) => {
    const newPositions = Cesium.Cartesian3.fromDegreesArrayHeights([
        lng, lat, getHeight(lat, lng) + Number(alt) - 1,
        lng, lat, 0
    ]);

    if (entitie) {
        // 현재 엔티티의 위치를 가져옴
        const currentPositions = entitie.polyline.positions.getValue(Cesium.JulianDate.now());

        // 이전 위치와 새로운 위치의 첫 번째 점 간의 거리 계산
        const distance = Cesium.Cartesian3.distance(currentPositions[0], newPositions[0]);

        // 1미터 이상 차이 나는 경우에만 위치 업데이트
        if (distance > 0.5) {
            entitie.polyline.positions.setValue(newPositions);
        }

        // 기존 material 객체의 색상만 업데이트
        if (entitie.polyline.material instanceof Cesium.PolylineDashMaterialProperty) {
            entitie.polyline.material.color.setValue(Cesium.Color.fromCssColorString(color));
        } else {
            entitie.polyline.material = new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.fromCssColorString(color),
                dashLength: 15.0,
            });
        }
        return entitie;
    } else {
        // 엔티티가 존재하지 않는 경우 새로 생성
        return ws3d.viewer.entities.add({
            polyline: {
                positions: newPositions,
                width: 4,
                material: new Cesium.PolylineDashMaterialProperty({
                    color: Cesium.Color.fromCssColorString(color),
                    dashLength: 15.0,
                })
            },
        });
    }
};

// export const createGroundDroneCircle = (lat, lng, entitie, color) => {
//     const circle = ws3d.viewer.entities.add({
//         position: Cesium.Cartesian3.fromDegrees(lng, lat),
//         ellipse: {
//             semiMinorAxis: 6.0,
//             semiMajorAxis: 6.0,
//             material: Cesium.Color.RED.withAlpha(0.5),
//             outline: true,
//             outlineColor: Cesium.Color.BLACK
//         }
//     })
//     deleteEntitie(entitie);
//     return circle;
// }

export const createGroundDroneCircle = (lat, lng, circleImg, entitie) => {
    const position = Cesium.Cartesian3.fromDegrees(lng, lat, getHeight(lat, lng) + 0.5);
    const entity = ws3d.viewer.entities.add({
        position: position,
        billboard: {
            image: circleImg,
            eyeOffset: new Cesium.Cartesian3(0, 0, 0),
            alignedAxis: Cesium.Cartesian3.UNIT_Z,
        },
    });
    deleteEntitie(entitie);
    return entity;
};

export const updateGroundDroneCircle = (lat, lng, circleImg, entitie) => {
    const newPosition = Cesium.Cartesian3.fromDegrees(lng, lat, getHeight(lat, lng) + 0.5);

    if (entitie) {
        // 현재 위치와 새로운 위치 간의 거리 계산
        const currentPosition = entitie.position.getValue(Cesium.JulianDate.now());
        const distance = Cesium.Cartesian3.distance(currentPosition, newPosition);

        // 1미터 이상 차이 나는 경우에만 위치 업데이트
        if (distance > 0.5) {
            entitie.position.setValue(newPosition);
        }

        // 이미지가 변경될 수 있다면 이미지도 업데이트
        if (entitie.billboard.image !== circleImg) {
            entitie.billboard.image = circleImg;
        }
        return entitie;
    } else {
        // 엔티티가 없는 경우 새로 생성
        return ws3d.viewer.entities.add({
            position: newPosition,
            billboard: {
                image: circleImg,
                eyeOffset: new Cesium.Cartesian3(0, 0, 0),
                alignedAxis: Cesium.Cartesian3.UNIT_Z,
            },
        });
    }
};
export const createMarker = (lng, lat, alt, degree, img, entitie) => {
    let height = getHeight(lat, lng);
    if (Number(alt) <= height + 1) {
        alt = height + 1;
    }
    const position = Cesium.Cartesian3.fromDegrees(lng, lat, alt);
    const entity = ws3d.viewer.entities.add({
        position: position,
        billboard: {
            image: img,
            eyeOffset: new Cesium.Cartesian3(0, 0, 0),
            alignedAxis: Cesium.Cartesian3.UNIT_Z,
            rotation: Cesium.Math.toRadians(degree),
        },
    });
    deleteEntitie(entitie);
    return entity;
};


export const createModel = (serialNumber, position) => {
    // const location = Cesium.Cartesian3.fromDegrees(position.lng, position.lat, 45);
    const location = Cesium.Cartesian3.fromDegrees(position.lng, position.lat, position.alt);
    const modelMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(location);
    const modelUrl = drone3DMarker;
    const model = ws3d.viewer.scene.primitives.add(Cesium.Model.fromGltf({
        id: serialNumber,
        url: modelUrl,
        scale: 1.0,
        modelMatrix: modelMatrix,
        minimumPixelSize: 10,
    }))

    init3DMatrix = modelMatrix;

    return model;
}

export const removeModel = (model) => {
    if (model) {
        const removed = ws3d.viewer.scene.primitives.remove(model);
        if (removed) {
            console.log(`Model with ID ${model.id} removed successfully.`);
        } else {
            console.log(`Model with ID ${model.id} could not be removed.`);
        }
    } else {
        console.log('Model is undefined or null, cannot remove.');
    }
}

export const positionModel = (model, position, yaw) => {
    /////////////////////////////////////////////////////////////////////////
    // TRANSLATION
    /////////////////////////////////////////////////////////////////////////

    const location = Cesium.Cartesian3.fromDegrees(position.lng, position.lat, position.alt);
    let translationMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(location);
    
    // 자세 기울어지는 문제 수정용 행렬값 변경
    translationMatrix[0] = init3DMatrix[0]
    translationMatrix[1] = init3DMatrix[1]
    translationMatrix[2] = init3DMatrix[2]
    translationMatrix[3] = init3DMatrix[3]
    translationMatrix[4] = init3DMatrix[4]
    translationMatrix[5] = init3DMatrix[5]
    translationMatrix[6] = init3DMatrix[6]
    translationMatrix[7] = init3DMatrix[7]
    translationMatrix[8] = init3DMatrix[8]
    translationMatrix[9] = init3DMatrix[9]
    translationMatrix[10] = init3DMatrix[10]
    translationMatrix[11] = init3DMatrix[11]

    /////////////////////////////////////////////////////////////////////////
    // ROTATION
    /////////////////////////////////////////////////////////////////////////

    const calcYaw = yaw - 90 < 0 ? yaw - 90 + 360 : yaw - 90;
    const rotationMatrix = Cesium.Matrix3.fromRotationZ(Cesium.Math.toRadians(360 - calcYaw));

    /////////////////////////////////////////////////////////////////////////
    // CREATE MATRIX
    /////////////////////////////////////////////////////////////////////////

    const modelMatrix = Cesium.Matrix4.multiply(
        translationMatrix,
        Cesium.Matrix4.fromRotationTranslation(rotationMatrix),
        new Cesium.Matrix4()
    );

    model.modelMatrix = modelMatrix;
    return model
}

export const deleteMaker = (entitie) => {
    deleteEntitie(entitie);
};

export const createLabel = (marker, text, entitie) => {
    var entity = ws3d.viewer.entities.add({
        position: marker.position,
        label: {
            text: text,
            font: "14pt Poppin",
            fillColor: Cesium.Color.BLACK,
            // style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            // outlineWidth: 2,
            // verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, -30),
        },
    });
    entity.label.position = marker.position;
    deleteEntitie(entitie);
    return entity;
};
export const deleteEntitie = (entitie) => {
    try {
        ws3d.viewer.entities.remove(entitie);
    } catch {

    }
};


export const createFlightPath = (waypointList, homeAlt) => {
    // let coordinates = [
    //     {latitude: 35.8099596, longitude: 127.1610438, altitude: 30 + home_alt},
    //     {latitude: 35.8079144, longitude: 127.1616075, altitude: 140 + home_alt},
    //     {latitude: 35.8068271, longitude: 127.1612311, altitude: 154 + home_alt},
    //     {latitude: 35.8050453, longitude: 127.1608098, altitude: 174 + home_alt},
    //     {latitude: 35.8033872, longitude: 127.1604236, altitude: 225 + home_alt},
    //     {latitude: 35.8013182, longitude: 127.1601920, altitude: 285 + home_alt},
    //     {latitude: 35.8006696, longitude: 127.1607994, altitude: 296 + home_alt},
    // ]

    let route = [];
    waypointList.map((waypoint) => {
        let position = Cesium.Cartesian3.fromDegrees(waypoint[1], waypoint[0], waypoint[2] + homeAlt);
        // let position = Cesium.Cartesian3.fromDegrees(coord.longitude, coord.latitude, coord.altitude);
        route.push(position);
    })

    const dash = ws3d.viewer.entities.add({
        polyline: {
            positions: route,
            width: 5.0,
            followSurface: true,
            material: Cesium.Color.WHITE,
            // material: new Cesium.PolylineDashMaterialProperty({
            //     color: Cesium.Color.WHITE.withAlpha(0.7),
            //     dashLength: 30.0,
            // })
        }
    })

    return dash;
}

export const createEmergencyPath = (dronePosition, emergencyPosition, homeAlt) => {
    let route = [];
    route.push(Cesium.Cartesian3.fromDegrees(dronePosition.longitude, dronePosition.latitude, dronePosition.altitude + homeAlt))
    route.push(Cesium.Cartesian3.fromDegrees(emergencyPosition.longitude, emergencyPosition.latitude, emergencyPosition.altitude + homeAlt));
    const dash = ws3d.viewer.entities.add({
        polyline: {
            positions: route,
            width: 5.0,
            followSurface: true,
            material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.RED,
                dashLength: 30.0,
            })
        }
    })
    return dash;
}

export const createGroundFlightPath = (positions) => {

    let position_list = [];
    positions.map((waypoint) => {
        position_list.push(Cesium.Cartesian3.fromDegrees(Number(waypoint.longitude), Number(waypoint.latitude), getHeight(Number(waypoint.latitude), Number(waypoint.longitude))));
        // position.push(Cesium.Cartesian3.fromDegrees(Number(station.longitude), Number(station.latitude), getHeight(Number(station.latitude), Number(station.longitude))));
        // position.push(Cesium.Cartesian3.fromDegrees(Number(destination.longitude), Number(destination.latitude), getHeight(Number(destination.latitude), Number(destination.longitude))));
    })

    const dash = ws3d.viewer.entities.add({
        polyline: {
            positions: position_list,
            width: 5.0,
            clampToGround: true,
            material: new Cesium.PolylineDashMaterialProperty({
                color: Cesium.Color.WHITE,
                dashLength: 10.0
            }),
            // material: new Cesium.PolylineOutlineMaterialProperty({
            //     color: Cesium.Color.WHITE.withAlpha(0.5),
            // }),
            // material: Cesium.Color.RED.withAlpha(0.5)
        },
    });
    return dash;
}
export const setCameraPositionAndOrientation = (position, orientation) => {
    // 현재 카메라 위치와 새로 설정할 위치의 차이를 비교해 작은 변화만 있을 때는 업데이트하지 않도록 조건을 추가할 수도 있음
    ws3d.viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, position.altitude),
        orientation: {
            heading: Cesium.Math.toRadians(orientation.heading),
            pitch: Cesium.Math.toRadians(orientation.pitch),
            roll: Cesium.Math.toRadians(orientation.roll)
        }
    });
}

export const getCameraPositionAndOrientation = () => {
    let camera = ws3d.viewer.camera;

    let positionCartographic = Cesium.Cartographic.fromCartesian(camera.position);
    let longitude = Cesium.Math.toDegrees(positionCartographic.longitude);
    let latitude = Cesium.Math.toDegrees(positionCartographic.latitude);
    let height = positionCartographic.height;

    let heading = Cesium.Math.toDegrees(camera.heading);
    let pitch = Cesium.Math.toDegrees(camera.pitch);
    let roll = Cesium.Math.toDegrees(camera.roll);

    return {
        position: {
            longitude: longitude,
            latitude: latitude,
            height: height
        },
        orientation: {
            heading: heading,
            pitch: pitch,
            roll: roll
        }
    };
}

export const  moveToNewPosition = (lat, lon, altitude, distance, angle) => {
    const R = 6371000; // 지구 반지름 (미터 단위)
    const dLat = distance * Math.cos(angle) / R;
    const dLon = distance * Math.sin(angle) / (R * Math.cos(lat * Math.PI / 180));

    const newLat = lat + dLat * (180 / Math.PI);
    const newLon = lon + dLon * (180 / Math.PI);

    return { latitude: newLat, longitude: newLon, altitude: altitude};
}

import axios from "axios";

export const sendWeightAPI = async(humidity,winsSpeed,slope) => {
  const result = await axios.get(process.env.REACT_APP_API_URL2 + `/api/wildfire/set/weights/${humidity}/${winsSpeed}/${slope}`);
	return result;
};

export const getPredictAPI = async(humidity,winsSpeed,slopeF,slopeB,slopeL,slopeR,hour) => {
  const result = await axios.get(process.env.REACT_APP_API_URL2+`/api/wildfire/predict/forestfire/${humidity}/${winsSpeed}/${slopeF}/${slopeB}/${slopeL}/${slopeR}/${hour}`);
	return result.data.data;
};
import React from 'react';
import styled from 'styled-components';

import VideoIcon from "../../gcs_image/common/video-icon.svg";
import ExtendVideoIcon from "../../gcs_image/common/extend-video-icon.svg";
import ReduceVideoIcon from "../../gcs_image/common/size-down-icon.svg";
import CloseIcon from "../../gcs_image/common/close-icon.svg";
import { useSelector } from "react-redux";

const S = {
    Wrap: styled.div`
        /* position: fixed;
        right: 30px;
        bottom: 74px;
        z-index: 99; */
    `,
    FoldWrap: styled.div`
        width: 56px;
        height: 56px;
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-sizing: border-box;

        /* Note: backdrop-filter has minimal browser support */
        border-radius: 3px;

        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 30px;
        bottom: 74px;
        z-index: 99;
        cursor: pointer;
    `,

    CameraWrap: styled.div`
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        position: fixed;
        right: 30px;
        bottom: 74px;
        z-index: 99;
        gap:7px;

        width: 390px;
        height: 258px;

        background: rgba(32, 31, 31, 0.5);
        border-radius: 2.78129px;

        .camera-title-wrap {
            display: flex;
            align-items: center;
            width:360px;
            height: 14px;

            .title-wrap {
                display: flex;
                align-items: center;

                .cam-title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: rgba(255, 255, 255, 0.7);
                }

                p {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    color: #FFFFFF;
                }
            }

            img {
                margin-left: auto;
                cursor: pointer;
            }
        }

        .camera-img {
            width: 360px;
            height: 213px;
            position: relative;

            .extend-btn-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 37px;
                height: 37px;
                background: rgba(0, 0, 0, 0.5);
                border: 1px solid rgba(255, 255, 255, 0.3);
                /* btn-shadow */
                box-shadow: 4px 4px 20px rgba(5, 69, 115, 0.25);
                border-radius: 3px;
                position: absolute;
                right: 10px;
                bottom: 13px;
                cursor: pointer;
            }
        }
    `,
    ExtendVideoWrap: styled.div`
        width: 100vw;
        height: 100vh;
        z-index: 999;
        position: fixed;
        background-color: white;

        .reduce-btn-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 64px;
            height: 64px;
            background-color: rgba(0, 0, 0, 0.30);
            /* background-color: white; */
            border-radius: 34px;
            position: absolute;
            right: 13px;
            bottom: 13px;
            cursor: pointer;
        }
    `,
}

function CameraView({selectedDroneSN , videoFoldState, setVideoFoldState}) {
    const { socket } = useSelector((state) => ({
        socket: state.drone.socket,
    }));
    if (selectedDroneSN !== "") {
        return (
            <S.Wrap>
                {videoFoldState === "fold" && (
                    <S.FoldWrap onClick={() => setVideoFoldState("open")}>
                        <img src={VideoIcon} alt="video-icon" />
                    </S.FoldWrap>
                )}
                {videoFoldState === "open" && (
                    <S.CameraWrap>
                        <div className="camera-title-wrap">
                            <div className="title-wrap">
                                <p><span className="cam-title">CAM SHUTTLE</span> #1</p>
                            </div>
                            <img src={CloseIcon} width="21px" height="21px" alt="close-icon" onClick={() => setVideoFoldState("fold")} />
                        </div>
                        <div className="camera-img">
                            { socket.getIsVirtualDrone() ? (
                                <img src={`/images/no_video.jpg`} width="360px" height="213px" alt="video" />
                            ) : (
                                <img src={`${process.env.REACT_APP_API_URL_MEDIA}/video_stream/${selectedDroneSN}`} width="360px" height="213px" alt="video" />
                            )
                            }
                           
                            <div className="extend-btn-wrap" onClick={() => setVideoFoldState("extend")}>
                                <img src={ExtendVideoIcon} width="14px" height="14px" alt="exnted-video-icon" />
                            </div>
                        </div>
                    </S.CameraWrap>
                )}
                {videoFoldState === "extend" && (
                    <S.ExtendVideoWrap>
                        { socket.getIsVirtualDrone() ? (
                            <img src={`/images/no_video.jpg`} width="100%" height="100%" alt="video" />
                        ) : (
                            <img src={`${process.env.REACT_APP_API_URL_MEDIA}/video_stream/${selectedDroneSN}`} width="100%" height="100%" alt="video" />     
                        )
                        }
                        <div className="reduce-btn-wrap" onClick={() => setVideoFoldState("open")}>
                            <img src={ReduceVideoIcon} width="29.5px" height="28.5px" alt="reduce-videl-btn" />
                        </div>
                    </S.ExtendVideoWrap>
                )}
            </S.Wrap>
        );
    }
}

export default CameraView;